import React from 'react';
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";

import Tutorial from './Pages/Tutorial/Tutorial';
import Login from './Pages/Login/Login';
import Forgot from './Pages/Forgot/Forgot';
import Register from './Pages/Register/Register';
import Profile from './Pages/Profile/Profile';
import Home from './Pages/Home/Home';
import Gamepick from './Pages/Gamepick/Gamepick';
import Addplayers from './Pages/Addplayers/Addplayers';
import Picture from './Pages/Picture/Picture';
import Player from './Pages/Player/Player';
import Pose from './Pages/Pose/Pose';
import Question from './Pages/Question/Question';
import Questionone from './Pages/Questionone/Questionone';
import Splash from './Pages/Splash/Splash';
import Gif from './Pages/Gif/Gif';
import Animation from './Pages/Animation/Animation';
import Logout from './Pages/Logout/Logout';
import Resetpsw from './Pages/Resetpsw/Resetpsw';
import Changepsw from './Pages/Changepsw/Changepsw';
import Privacy from './Pages/Privacy/Privacy';
import Terms from './Pages/Terms/Terms';


function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Splash />} />
          <Route exact path="/tutorial/" element={<Tutorial />} />
          <Route exact path="/login/" element={<Login />} />
          <Route exact path="/forgot/" element={<Forgot />} />
          <Route exact path="/register/" element={<Register />} />
          <Route exact path="/profile/" element={<Profile />} />
          <Route exact path="/home/" element={<Home />} />
          <Route exact path="/gamepick/" element={<Gamepick />} />
          <Route exact path="/addplayer/" element={<Addplayers />} />
          <Route exact path="/picture/" element={<Picture />} />
          <Route exact path="/player/" element={<Player />} />
          <Route exact path="/Pose/" element={<Pose />} />
          <Route exact path="/top/" element={<Question />} />
          <Route exact path="/bottom/" element={<Questionone />} />
          <Route exact path="/splash/" element={<Splash/>} />
          <Route exact path="/video/" element={<Gif/>} />
          <Route exact path="/animation/" element={<Animation/>} />
          <Route exact path="/logout/" element={<Logout/>} />
          <Route exact path="/resetpsw/" element={<Resetpsw/>} />
          <Route exact path="/changepsw/" element={<Changepsw/>} />
          <Route exact path="/privacy/" element={<Privacy/>} />
          <Route exact path="/terms/" element={<Terms/>} />
        </Routes>
      </Router>
                
    </>
  );
}

export default App;