import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Container  } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Gif.css';

const Gif = () => {
const navigate = useNavigate();

useEffect(() => {
  document.title = `GIF | PVRPOSE`;
  document.getElementById('bodywrap').className='gifpg';  
  const timer = setTimeout(() => {
    navigate('/home');
  }, 4500);
  return () => clearTimeout(timer);
}, []);
 
    return (
      <>
      <section className="gen-wrap gifwrap">
        <Container>
          <Row>
            <Col className="text-center">
              <img src="assets/images/pvrpose.gif" className="img-fluid " alt="Logo"/>      
            </Col>
          </Row>
        </Container>
      </section> 

{/*      <div className="text-center">
        <img src="assets/images/pvrpose.gif" className="img-fluid " alt="Logo"/>
      </div>*/}

      </>
    )
}
export default Gif;










  
  

  
