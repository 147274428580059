import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Image, Button,Modal} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './Tutorial.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Typewriter from 'typewriter-effect';
import soundFile from '../tick.wav';

//import { useNavigate } from 'react-router-dom';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/autoplay";
import { EffectCards, Autoplay } from 'swiper/modules';

import logopwa from "./img/logopwa.png";
import uploadpwa from "./img/upload.png";
import pluspwa from "./img/download.png";


const Tutorial = () => {
  const [audio] = useState(new Audio(soundFile)); 
  const playAudio = () => {
    if(sessionStorage.getItem('tickData') != 'unmute'){
      audio.play(); 
    }    
  };
//  const navigate = useNavigate();
const [displayText, setDisplayText] = useState('');
const [showandroid, setShowandroid] = useState('');  
const [showiphone, setShowiphone] = useState('');
const [deferredPrompt, setDeferredPrompt] = useState("");

useEffect(() => {
  document.title = `Tutorial | PVRPOSE`;
  document.getElementById('bodywrap').className='tutralpg';
  setDisplayText('How To Play PVRPOSE <br/>  1) pick a player <br/> 2) pick a card <br/> 3) ask "top or bottom" <br/> let the rest unfold.');
  // const timer = setTimeout(() => {
  //   navigate('/video');
  // }, 4000);
  // return () => clearTimeout(timer);


if (window.matchMedia('(display-mode: standalone)').matches) {
    //alert("This is running as standalone.");
    var sd=navigator.userAgent.match(/iPhone|iPad|iPod/i);
   
    if(sd==null)
    {
      
    }else{
      const stringValue = sd.toString();
      var type_phone=stringValue;
      if(type_phone ==='iPhone' || type_phone ==='iPad' || type_phone ==='iPod'){
        //setShowiphoneper(true);
        //requestPermission_chk();
      }
    }
  }else{
    
    var sd1=navigator.userAgent.match(/iPhone|iPad|iPod/i);
    if(sd1==null)
    {
      
    }else{
      const stringValue = sd1.toString();
      var type_phone1=stringValue;
      //alert(type_phone);
      if(type_phone1 ==='iPhone' || type_phone1 ==='iPad' || type_phone1 ==='iPod'){
        /*setTimeout(function(){ 
          var hours = 120; // Reset when storage is more than 24hours
        var now = new Date().getTime();
        var setupTime = localStorage.getItem('setupTime');  
        var tt=0;
         if(setupTime==null)
         {
           
         }else{
          var tt=parseInt(setupTime);
         }
        var t=now-tt;
        if(t > hours*60*60*1000) {
           setShowiphone(true);
        }
      }, 2000);*/
      setShowiphone('activvi');      
      }
    }
  }
    
  window.addEventListener('beforeinstallprompt', (e:any) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
  
  
  const dp = getPWADisplayMode();
    
     if (dp === 'browser') {
       setShowandroid('activev');
           /*setTimeout(function(){ 
          var hours = 120; // Reset when storage is more than 24hours
        var now = new Date().getTime();
        var setupTime = localStorage.getItem('setupTime');  
        var tt=0;
         if(setupTime==null)
         {
           
         }else{
          var tt=parseInt(setupTime);
         }
        var t=now-tt;
        if(t > hours*60*60*1000) {
           setShowandroid(true);
        }
      }, 2000);*/
     }
     
   });
   
   window.addEventListener('beforeinstallprompt', (event) => {
  // Prevent the default prompt
  event.preventDefault();
  // Store the event for later use

  setDeferredPrompt(event);
});

},[])

function getPWADisplayMode() {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  if (document.referrer.startsWith('android-app://')) {
    return 'twa';
  } else if (isStandalone) {
    return 'standalone';
  }
  return 'browser';
}


function showInstallPrompt() {
  setShowandroid('');
  // Show your custom install button or other UI element
  // and handle the installation when the user clicks it
  if (deferredPrompt) {
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === 'accepted') {
     
      
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      //deferredPrompt = null;
    });
  }
}

const handleCloseandroid = () =>{
  setShowandroid('');

  /*var now:any;
  now= new Date().getTime();
  localStorage.setItem('setupTime', now);*/
}

const handleCloseiphone = () =>{
  setShowiphone('');
  /*var now:any;
  now= new Date().getTime();
  localStorage.setItem('setupTime', now);*/
}
    return (
      <>
      <section className="gen-wrap" id="showaftr">
       <Container>
        <Row className="h-100-center p-20">
          <Col md={12} lg={12} xl={12} xxl={12} className="text-center">
          <Row className="justify-content-md-center mx-0">
          <Col md={10} lg={8} xl={12} xxl={10} className="text-start typrtxt">
            {/*<Typewriter options={{strings: [displayText], autoStart: true, loop: true, delay: 25,}}/>*/}
              <Typewriter options={{ autoStart: true, loop: false, delay: 25, }} onInit={(typewrier) => {
              typewrier.typeString('How to play PVRPOSE? <br/>  1. Pick a player <br/> 2. Pick a card <br/> 3. Ask Top or Bottom <br/> Let the rest unfold!').start();
            }}
            />
          </Col>
          <Col xs="12" lg="8" className="">
               <Swiper
                  effect={"cards"}
                  autoplay={{
                    "delay": 2000,
                    "disableOnInteraction": false
                  }}                  
                  grabCursor={true}
                  modules={[EffectCards, Autoplay]}
                  className="mySwiper">                  
                  <SwiperSlide><Image src="assets/images/card1.png" fluid /></SwiperSlide>
                  <SwiperSlide><Image src="assets/images/card2.png" fluid /></SwiperSlide>
                  <SwiperSlide><Image src="assets/images/card3.png" fluid /></SwiperSlide>
                  <SwiperSlide><Image src="assets/images/card4.png" fluid /></SwiperSlide>
                  <SwiperSlide><Image src="assets/images/card5.png" fluid /></SwiperSlide>
                  <SwiperSlide><Image src="assets/images/card6.png" fluid /></SwiperSlide>
                </Swiper>            
                <div className="box-card d-none">
                   <p>TOP?</p>
                   <Image src="assets/images/icon.png" fluid />
                   <p>BOTTOM?</p>
                </div>
            </Col>
            <Col xs="8" lg="8" md="7" sm="7"  className="boxwrap skipbutton"><NavLink to="/home" onClick={playAudio}><Button variant="grdantpink">SKIP</Button></NavLink></Col>
          </Row>
         </Col> 
          {/* ========== Android Popup Start ========== */}
      <div  onHide={handleCloseandroid} className={`msmodal androidmodel ${showandroid}`}>
        
         <div className="modelbody">
          <div className="row pwa-main-row">
          
            <div>
              <p className="text-center">Install PVRPOSE APP</p>
            </div>
          </div>
        <div className="row mt-3 pwa-body">
                    <div className="col-12 px-3 topmainandriddiv">
                      <span onClick={() =>showInstallPrompt()} className="pwa-install-btn"> Install APP </span>
                      <img src="assets/images/lp.png" className="img-fluid modelandroidimg" />
                    </div>
                    </div> 
             
         </div>
         <div className="footermodel">
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() =>handleCloseandroid()}  >Close</button>
         </div>
      </div>
    {/* ========== Android Popup End ========== */}
    {/* ========== iphone Popup Start ========== */}
      <div onHide={handleCloseiphone} className={`msmodal iphonedmodel ${showiphone}`}>                 

         <div className="modelbody">
          <div className="row pwa-main-row">
          
            <div>
              <p className="text-center">Install PVRPOSE APP</p>
              <p className="text-center">For iOS Device PWA can be install using Safari Browser only.</p>
            </div>
          </div>
        <div className="row mt-3 pwa-body">
                    <div className="col-12 px-3 topmainandriddiv">
                    <ul>
                      <li><span>1. Tap on</span> <span><img src={pluspwa} className="img-fluid img-pwa-upload" /></span></li>
                      <li><span>2. Select</span> <span>Add to Home Screen <img src={uploadpwa} className="img-fluid img-pwa-upload" /></span> </li>
                    </ul>
                     {/* <span onClick={() =>showInstallPrompt()} className="pwa-install-btn"> Install APP </span>
                      <img src="assets/images/lp.png" class="img-fluid modelandroidimg" />*/}
                    </div>
                    </div> 
             
         </div>
         <div className="footermodel">
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() =>handleCloseiphone()}  >Close</button>
         </div>
      </div>
    {/* ========== iphone Popup End ========== */}
        </Row>
       
       </Container>
       
      </section>

      </>
    )
}
export default Tutorial;