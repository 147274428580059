import React, { useEffect, useState  } from 'react';
import { Row, Col, Container, Image,Button} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './Profile.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Topbar from "../Topbar";
import axios from "axios";
import soundFile from '../tick.wav';
import vic from "./img/eyc00.png";
import ema from "./img/eyc001.png";

const Profile = () => {
  const [audio] = useState(new Audio(soundFile)); 
  const playAudio = () => {
    if(sessionStorage.getItem('tickData') != 'unmute'){
      audio.play(); 
    }    
  };
  const [tickData, settickData] = useState('');  
  const [showDiv1, setShowDiv1] = useState(true);
  const [showDiv2, setShowDiv2] = useState(true);

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const userid = userData?.userid;
  const [name, setName] = useState(vic);

useEffect(() => {  
   document.title = `Profile | PVRPOSE`;
   document.getElementById('bodywrap').className='tutralpg';
   window.scrollTo({top: 0,left: 0,behavior: 'smooth',}); 

   const storedSessionData = sessionStorage.getItem('tickData');
    if (storedSessionData) {
      settickData(storedSessionData);
    }

    setShowDiv1('');
    setShowDiv2('hidediv');       
 if(sessionStorage.getItem('tickData')=='unmute'){
      setShowDiv1('hidediv');
      setShowDiv2('');    
    }
},[])  


//   console.log('sssssssssss');
// console.log(sessionStorage.getItem('tickData'));

const handleSaveToSession = () => {
    const data = 'unmute';
    settickData(data);
    sessionStorage.setItem('tickData', data);
    setShowDiv1('hidediv');
    setShowDiv2('');
  };

  const handleClearSession = () => {
    settickData('');
    sessionStorage.removeItem('tickData');
    setShowDiv1('');
    setShowDiv2('hidediv'); 
    audio.play();   
  };

  const changeName = () => {
    let value = name;
    if(value === vic){
      setName(ema);
    }else{
      setName(vic);
    }
  };

const [post, setPost] = React.useState(null);
const [nm, setnm] = React.useState(null);
const [img, setImg] = React.useState(null);
const [gamecount, setgame] = React.useState(null);
const [players, setplayes] = React.useState(null);


  React.useEffect(() => {
    console.log('img',img);
    const params = {
      userid: userid
    };
    axios.get('https://pvrpose.app/pvr/apis/items/profile.php', { params }).then((response) => {
      setPost(response.data);
      setnm(response.data.nm);
      setImg(response.data.image);   
      console.log(response.data.image)   
      if(response.data.image=='' || response.data.image==null){
        // setImg('assets/images/plyr-imgone.png');  
        setImg('assets/images/usericon.png');  
      }      
      setplayes(response.data.ttlplyr);
      setgame(response.data.gamecount);
     // console.log(response.data);
    });
  }, []);

  

    return(
      <>      
      <section className="gen-wrap">
      <Topbar/>
       <Container>
        <Row className="p-20 profilemainrow "> {/*h-100-center-normal*/}
         <Col md={12} lg={12} xl={12} xxl={12} className="text-start mt-3 hideonthis"><Image src="assets/images/svg/logo.svg" className="d-none" fluid /></Col>
        <Row className="justify-content-md-center mx-0">
          <Col xs="12" lg="8" className="detail-prflr text-center detail-prflr_profile">
          <a href="/picture"><div className="borderbox borderbox_profu"><Image src={img} fluid /></div>
          <p className="txt-head">Add your picture (Optional)</p></a>
          <h3>{nm}</h3>
          {/* <div className="sound-btn"><Image src="assets/images/svg/sound.svg" fluid /></div> */}
          {/*<div className="mt-5"><Image src={name} onClick={changeName} fluid /></div>*/}
          <div className={`mt-5 volumeicon ${showDiv1}`} onClick={handleSaveToSession}><Image src="assets/images/eyc002un.png" fluid /></div> 
          <div className={`mt-5 volumeicon ${showDiv2}`} onClick={handleClearSession}><Image src="assets/images/eyc002.png" fluid /></div>          

          {/* <button onClick={handleSaveToSession}>Save Data to Session</button>
          <button onClick={handleClearSession}>Clear Session Data</button>*/}

          <h4>Total Games Played <span>{gamecount}</span></h4>
          <h4>Total New Connections Made <span>{players}</span></h4>
          <Col xs="12" lg="12" className="text-center prfly-lst-txt class_fortxt">
            <p>Expand your connection.</p>
          <Row className="justify-content-md-center g-0 mt-3">
           {/* <Col xs="4" lg="4" className="text-center"><div className="p-3 icn-box"><Image src="assets/images/svg/picon1.svg" fluid /></div></Col>
           <Col xs="4" lg="4" className="text-center"><div className="p-3 icn-box"><Image src="assets/images/svg/picon2.svg" fluid /></div></Col>
           <Col xs="4" lg="4" className="text-center"><div className="p-3 icn-box"><Image src="assets/images/svg/picon3.svg" fluid /></div></Col> */}
           <Col xs="4" lg="4" className="text-center"><a href="https://www.instagram.com/pvrpose?igsh=MTB1aGN1aWRncnZvMQ==" target="_blank"><Image src="assets/images/eyc11.png" fluid /></a></Col>
           <Col xs="4" lg="4" className="text-center"><a href="https://www.youtube.com/watch?v=_0uIvl7Fg8M" target="_blank"><Image src="assets/images/eyc22.png" fluid /></a></Col>
           <Col xs="4" lg="4" className="text-center"><a href="https://www.instagram.com/pvrpose?igsh=MTB1aGN1aWRncnZvMQ==" target="_blank"><Image src="assets/images/eyc33.png" fluid /></a></Col>           
          </Row>
          </Col>
          </Col>
            <Col xs="12" lg="12" className="mt-100 chpswandstartgm justify-content-md-center chpswandstartgm_btm">
             <NavLink to="/changepsw"  onClick={playAudio}><p className="">Change The Password</p></NavLink>      
             <NavLink to="/home"  onClick={playAudio}><Button className="startgame strbtn"><span>HOME</span></Button></NavLink>
             </Col>
             {/*<Col xs="12" lg="10" className="mt-100">
              
            </Col>*/}
        </Row>
        </Row>
       </Container>
      </section>
      </>
    )
}
export default Profile;