import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Image, Button, Form } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './Register.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import Alert from "@mui/material/Alert";
import CircularProgress from '@mui/material/CircularProgress';
const Register = () => {
  useEffect(() => {
    document.title = `Register | PVRPOSE`;
    document.getElementById('bodywrap').className = 'tutralpg';
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth', });
  }, [])
  const [fnm, setFnm] = useState("");
  const [lnm, setLnm] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setcPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    removeAlert(success, error)
  }, [success, error]);
  const handleFnmChange = (event) => {
    setFnm(event.target.value);
  };
  const handleLnmChange = (event) => {
    setLnm(event.target.value);
  };
  const handlelocatinChange = (event) => {
    setLocation(event.target.value);
  };
  const handlephnChange = (event) => {
    setPhone(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handlecPasswordChange = (event) => {
    setcPassword(event.target.value);
  };
  const resetData = () => {
    setFnm('');
    setLnm('');
    setEmail('');
    setPhone('');
    setLocation('');
    setPassword('');
    setcPassword('');
  };
  const removeAlert = (success, error) => {
    if (success) {
      setTimeout(() => {
        setSuccess('');
      }, 3000);
    }
    if (error) {
      setTimeout(() => {
        setError('');
      }, 3000);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    axios.post("https://pvrpose.app/pvr/apis/items/register.php", {
      fnm: fnm,
      lnm: lnm,
      email: email,
      password: password,
      cpassword: cpassword,
      location: location,
      phone: phone,
    })
      .then((response) => {
        // console.log(response);
        setLoading(false);
        setError('');
        if (response.data.status === "success") {
          // window.location.href = "/login";
          setError('');
          setSuccess(response.data.message);
          resetData();
        } else {
          setError(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  return (
    <>
      <section className="gen-wrap">
        <Container className={isLoading ? 'loader-container' : ''}>
          {isLoading ?
            <div class="overlay-container">
              <CircularProgress color='error' />
            </div>
          : <></>}
          <Row className="p-20 h-100-center-normal">
            <Col md={12} lg={12} xl={12} xxl={12} className="text-start mt-3">
              <Image src="assets/images/svg/logo.svg" fluid />
            </Col>
            <Row className="justify-content-md-center mx-0">
              <Col xs="9" lg="8" md="8" className="detail-login">
                <p className="txt-head">Register</p>
                {error && (
                  <Alert style={{ marginBottom: "20px" }} severity="error">
                   
                  <div className="erroor" dangerouslySetInnerHTML={{__html: error}} />
                  </Alert>
                )}
                {success && (
                  <Alert style={{ marginBottom: "20px" }} severity="success">
                    {success}

                  </Alert>
                )}
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Control type="text" value="" onChange={handleFnmChange} value={fnm} id="fname" placeholder="First Name" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Control type="text" value="" onChange={handleLnmChange} value={lnm} id="lname" placeholder="Last Name" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Control type="email" onChange={handleEmailChange} value={email} id="email" placeholder="Email" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Control type="number" value="" onChange={handlephnChange} value={phone} id="phno" placeholder="Phone (Optional)" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Control type="text" value="" onChange={handlelocatinChange} value={location} id="phno" placeholder="Location (Optional)" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="password">
                    <Form.Control type="password" value='' onChange={handlePasswordChange} value={password} id="password" placeholder="Password" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="password">
                    <Form.Control type="password" value='' onChange={handlecPasswordChange} value={cpassword} id="password" placeholder="Confirm Password" />
                  </Form.Group>
                  <Button className="mb-3 text-start px-md-4 px-3 register-btn" id="register-btn" variant="grdantpink" type="submit">SUBMIT</Button>
                  {/*<NavLink to="#"><Button className="mb-3 text-start px-md-4 px-3" variant="grdantpink"><i class="goglicon"><img src="assets/images/google.png" className="img-fluid " alt="Google_Icon"/></i> Sign in with Google</Button></NavLink>*/}
                  <div className="dwn-txt-lgn">
                    <NavLink to="/login"><p>Login</p></NavLink>
                    {/*<NavLink to="#"><p>forgot the password?</p></NavLink>*/}
                  </div>
                </Form>
              </Col>
              <Col xs="12" lg="6" className="align-self-center d-none">
                <Row className="justify-content-md-center trm-prvcy-txt">
                  <Col xs="6" lg="6" className="text-center"><NavLink to="#"><p>Terms</p></NavLink></Col>
                  <Col xs="6" lg="6" className="text-center"><NavLink to="#"><p>Privacy</p></NavLink></Col>
                </Row>
              </Col>
            </Row>
          </Row>
        </Container>
      </section>
    </>
  )
}
export default Register;