import React, { useEffect, useState, useRef  } from 'react';
import { Row, Col, Container, Image, Button, Form} from 'react-bootstrap';
import { useLocation } from 'react-router';
import { NavLink } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Addplayers.css';
import Topbar from "../Topbar";
import axios from "axios";
import Alert from "@mui/material/Alert";
import $ from 'jquery';
import queryString from 'query-string';
import soundFile from '../tick.wav';


const Addplayers = () => {
  const [audio] = useState(new Audio(soundFile)); 
  const playAudio = () => {
    if(sessionStorage.getItem('tickData') != 'unmute'){
      audio.play(); 
    }    
  };

const userData = JSON.parse(sessionStorage.getItem("userData"));
const userid = userData?.userid;
useEffect(() => {  
   document.title = `Add-Players | PVRPOSE`;
   document.getElementById('bodywrap').className='plyrpg';
   window.scrollTo({top: 0,left: 0,behavior: 'smooth',}); 
},[])   

var typeset='';
if(queryString.parse(window.location.search).type){
  typeset=queryString.parse(window.location.search).type;
}else{
  typeset='';
}
const [error, setError] = useState("");

const initialized = useRef(false);
useEffect(() => {
  if (!initialized.current) {
    initialized.current = true
   // $(".addmoreplayer").click(function(){
$(document).on('click','.addmoreplayer',function(){     
   // var numItems = $('.inputallmain').length
    var numItems=$(this).attr('totalcount');
    var tt=parseInt(numItems)+1;

    var s='<div class="appendata mb-3 adpinp px-0 col-lg-10 col-12">\
     <div class="g-3 row">\
        <div class="col">\
           <div class="">\
              <div><input placeholder="Name" inputno="'+tt+'" type="text" class="form-control inputallmain inputno_'+tt+'_n" id="exampleForm.ControlInput1" ></div>\
           </div>\
           <div class="plyer-icon">\
              <label class="inpuupload"><input type="file"  accept="image/png, image/gif, image/jpeg"  class="fileup_user" id="fileno_'+tt+'_n" fileno="'+tt+'"></label><a class="" href="/picture"><img src="assets/images/svg/optional-icon.svg" class="imagemain_'+tt+'_s img-fluid" imagecount="'+tt+'"></a>\
              <p class="ptag_'+tt+'_main">(Optional)</p>\
           </div>\
        </div>\
     </div>\
    </div>';
    $('.appenddatmai').append(s);
    $(this).attr('totalcount',tt);

  });
  
$(document).on('change','.fileup_user',function(){   
     var fileno=$(this).attr('fileno');    
     makeRequest(fileno);
  }); 

  $(document).on('click','.startgame',function(){
      var result = [];
      var allFilled = true;
      $(".inputallmain").each(function() {
          if ($(this).val().trim() == '') {
                  allFilled = false;
                  return false; // Exit the loop early if any required input is blank
          }
          var inputno=$(this).attr('inputno');
          var vall=$(this).val();

          var img='';
          if($(".imagemain_"+inputno+"_s").hasClass("adeddimg")){
            var img=$(".imagemain_"+inputno+"_s").attr('src');
          }
        result.push({name: vall,img: img,userid:userid });
      });

      if (allFilled) {
            savePlayers(result,typeset);
        } else {
             setError('Please add player name.');
        }


     // console.log(result);
     // 
  });     


}
}, []);


async function savePlayers(result,typeset){  

        const formData = new FormData();
        var  myJsonString= JSON.stringify(result);
        // Append file to the formData object here
        formData.append("result", myJsonString);
        if(typeset==''){
          typeset='';
        }       
        formData.append("typeset", typeset);        

        const response = await axios.post("https://pvrpose.app/pvr/apis/items/add_players.php", formData, {
                headers: {"Content-Type": "multipart/form-data"}
            }).then((res) => { 
              if(res.data.typeset !=''){
                var uniqid=res.data.uniqid;
                window.location.href = "/player?type="+res.data.typeset+"&uid="+uniqid;
              }else{
                window.location.href = "/player";
              }                    
            }).catch((error) => {
               // alert("Error")
            });
}

async function makeRequest(fileno){    
        var sd=$("#fileno_"+fileno+"_n")[0].files[0];

        const formData = new FormData();
        $('.ajaxloder').show();
        $('.ptag_'+fileno+'_main').html('Wait..');
        // Append file to the formData object here
        formData.append("selectedFile", sd);
        formData.append("fileno", fileno);

        const response = await axios.post("https://pvrpose.app/pvr/apis/items/friendpic.php", formData, {
                headers: {"Content-Type": "multipart/form-data"}
            }).then((res) => {    
                var fileno=res.data.fileno;             
                $('.ptag_'+fileno+'_main').html('(Optional)');
                 if(res.data.status=='0'){
                  alert('Please select image....');
                  setTimeout(function () {
                      $('.ajaxloder').hide();
                  }, 3000);
                 
                 }else{                  
                  var img=res.data.nm;
                  $('.imagemain_'+fileno+'_s').attr('src',img);
                  $('.imagemain_'+fileno+'_s').addClass('adeddimg');
                    setTimeout(function () {
                        $('.ajaxloder').hide();
                    }, 3000);
                 }
            }).catch((error) => {
               // alert("Error")
            });
}

    return (
      <>
      <section className="gen-wrap">
      <Topbar/>
       <Container>
        <Row className="h-100-center-nev p-20">
         <Col md={12} lg={12} xl={12} xxl={12} className="text-center px-0">
          <Row className="justify-content-md-center mx-0">
            <Col xs="12" lg="10" className="mb-4 playr-top-txt">
              <h3>Add Players:</h3>
              {error && (
                <Alert style={{ marginBottom: "20px" }} severity="error">
                  {error}
                </Alert>
              )}
            </Col>
            <Col xs="12" lg="10" className="mb-3 adpinp px-0">
              <Row className="g-3">
                <Col>
                 <div className="">
                  <Form.Group controlId="exampleForm.ControlInput1">
                   <Form.Control type="text" className="inputallmain inputno_1_n" placeholder="Name" inputno="1" />
                  </Form.Group>
                 </div>                                 
                    <div className="plyer-icon"> 
                    <label className="inpuupload"><input type="file" accept="image/png, image/gif, image/jpeg" id="fileno_1_n" className="fileup_user" fileno="1" /></label>                                                               
                      <NavLink to="#">
                        <Image src="assets/images/svg/optional-icon.svg" fluid className="imagemain_1_s" imagecount="1" />
                        <p className="ptag_1_main">(Optional)</p>
                      </NavLink>      
                    </div>                 
                </Col>
              </Row>
            </Col>

            <Col xs="12" lg="10" className="mb-3 adpinp px-0">
              <Row className="g-3">
                <Col>
                 <div className="">
                  <Form.Group controlId="exampleForm.ControlInput1">
                   <Form.Control type="text" className="inputallmain inputno_2_n" placeholder="Name" inputno="2" />
                  </Form.Group>
                 </div>                                 
                  <div className="plyer-icon">
                 <label className="inpuupload"><input type="file"  accept="image/png, image/gif, image/jpeg"  id="fileno_2_n" className="fileup_user" fileno="2" /></label>    
                    <NavLink to="#">
                      <Image src="assets/images/svg/optional-icon.svg" fluid className="imagemain_2_s" imagecount="2" />
                    </NavLink> 
                   <p className="ptag_2_main">(Optional)</p>
                   </div>
                </Col>
              </Row>
            </Col>

            <div className="appenddatmai">
              
            </div>

            <Col xs="12" lg="10" className="mb-3 adpinp px-0">
              <Col xs="12" md="10" lg="10" className="ampbtn mt-0">
                <NavLink to="#">
                  <Button className="addmoreplayer text-center px-md-4 px-3" totalcount="2"  onClick={playAudio}>Add More Players</Button>
                </NavLink>               
              </Col>
            </Col>
            <Col xs="12" lg="10" className="mt-100">
              <NavLink to="#"><Button className="startgame mb-4 strbtn" variant="grdantpink"  onClick={playAudio}><span>START</span></Button></NavLink>
            </Col>
          </Row>
         </Col>
        </Row>
        <div className="ajaxloder"><img src="assets/images/l2.svg" /></div> 
       </Container>
      </section>
      </>
    )
}
export default Addplayers;