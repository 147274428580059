import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Splash.css';

const Splash = () => {
const navigate = useNavigate();

useEffect(() => {
  const timer = setTimeout(() => {
    navigate('/tutorial');
  }, 2500);
  return () => clearTimeout(timer);
}, []);
 
    return (
      <>
      <div className="splash text-center">
        <img src="assets/images/pvr-aniamtion.gif" className="img-fluid " alt="Logo"/>
      </div>
      </>
    )
}
export default Splash;










  
  

  
