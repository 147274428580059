import React, { useEffect,useState } from 'react';
import { Row, Col, Container, Image,Button, Form} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Picture.css';
import Topbar from "../Topbar";
import axios from "axios";
import Alert from "@mui/material/Alert";
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Preferences } from '@capacitor/preferences';
import { Capacitor } from '@capacitor/core';
import $ from 'jquery';

const Picture = () => {

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const userid = userData?.userid;

useEffect(() => {  
   document.title = `Picture | PVRPOSE`;
   document.getElementById('bodywrap').className='pictrpg';
   window.scrollTo({top: 0,left: 0,behavior: 'smooth',}); 
},[])


 const takePhoto = async () => {
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 100,
    });
  };

const [selectedFile, setSelectedFile] = useState(null);
 const [error, setError] = useState("");
//const [selectedId, setSelectedId] = useState(null);

  const handleSubmit = async (event) => {
        event.preventDefault();

        // Create a FormData object
        const formData = new FormData();

        // Append file to the formData object here
        formData.append("selectedFile", selectedFile);
        formData.append("userid", userid);

        try {
            // We will send formData object as a data to the API URL here.
             $('.ajaxloder').show();
            const response = await axios.post("https://pvrpose.app/pvr/apis/items/upload.php", formData, {
                headers: {"Content-Type": "multipart/form-data"}
            }).then((res) => {         
                 $('.ajaxloder').hide();        
                 
                 if(res.data=='0'){
                  setError('Please select image....');
                 }
                 if(res.data=='1'){
                  setError();
                  window.location.href = "/profile";
                 }
            }).catch((error) => {
                //alert("Error")
                $('.ajaxloder').hide();
            });

        } catch (error) {
            console.log(error)
        }
    }

  const handleFileSelect = (event) => {
    // we only get the selected file from input element's event
    setSelectedFile(event.target.files[0])
  }

// console.log('bbbbbbbbbb');
// console.log(userid);

    return (
      takePhoto,
      <>
      <section className="gen-wrap">
      <Topbar/>
       <Container>
        <Row className="h-100-center-nev p-20">
         <Col md={12} lg={12} xl={12} xxl={12} className="text-center">
          <Row className="justify-content-md-center mx-0">
          {error && (
            <Alert style={{ marginBottom: "20px" }} severity="error">
              {error}
            </Alert>
          )}          

          <form onSubmit={handleSubmit}>
            <Col xs="12" lg="12" className="mb-4">
              <div className="picture-icon">              
                <Image src="assets/images/svg/optional-icon.svg" fluid />
                <label className="inpuupload"><input type="file" accept="image/png, image/gif, image/jpeg" onChange={handleFileSelect}/></label>
                <span>Upload Photo</span>
              </div>
            </Col>
           {/* <Col xs="12" lg="12" className="mb-4">
              <div className="picture-icon">
                <Image src="assets/images/svg/camera-icon.svg" fluid />
                <span>Take a photo now!</span>
              </div>
            </Col>*/}
            <Col xs="12" lg="12" className="piclinkupload">
             <Button className="mb-3 px-md-4 px-3 pic-submit" type="submit">SUBMIT</Button>
             {/*<Button className="mb-3 text-start px-md-4 px-3" variant="grdantpink" type="submit"><span>SUBMIT</span><Image src="assets/images/lp.png" fluid /></Button>*/}
            </Col>
            </form>
             <Col xs="12" lg="10"><NavLink to="/profile"><Button className="mb-3 text-center px-md-4 px-3 pic-link">Cancel</Button></NavLink></Col>            
             {/*<Col xs="12" lg="10"><NavLink to="#"><Button className="mb-3 text-center px-md-4 px-3 pic-link">Submit</Button></NavLink></Col>*/}
            {/*<Col xs="12" lg="12" className="piclink"><NavLink to="/profile"><span>Cancel</span><Image src="assets/images/lp.png" fluid /></NavLink></Col>*/}
            
          </Row>

         </Col>
        </Row>
<div className="ajaxloder"><img src="assets/images/l2.svg" /></div> 
       </Container>
      </section>
      </>
    )
}
export default Picture;