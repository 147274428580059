import React, { useEffect, useState  } from 'react';
import { Row, Col, Container, Image,Button} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './Privacy.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Topbar from "../Topbar";
import axios from "axios";

import vic from "./img/eyc00.png";
import ema from "./img/eyc001.png";

const Privacy = () => {
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const userid = userData?.userid;
  const [name, setName] = useState(vic);

useEffect(() => {  
   document.title = `Privacy | PVRPOSE`;
   document.getElementById('bodywrap').className='tutralpg';
   window.scrollTo({top: 0,left: 0,behavior: 'smooth',}); 
},[])  

  const changeName = () => {
    let value = name;
    if(value === vic){
      setName(ema);
    }else{
      setName(vic);
    }
  };

const [content, setcontent] = React.useState('');



  React.useEffect(() => {
  const params = {
    userid: ''
  };
    axios.get('https://pvrpose.app/pvr/apis/items/privacy.php', { params }).then((response) => {    
      setcontent(response.data.content);      

    });
  }, []);

  const decodeHtmlEntities = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent;
  };
  const decodedString = decodeHtmlEntities(content);

    return(
      <>      
      <section className="gen-wrap">     
       <Container>
        <Row className="p-20 h-100-center-normal">   
        <Col md={12} lg={12} xl={12} xxl={12} className="text-start mt-3">
          <a href="/"><Image src="assets/images/svg/logo.svg" fluid /></a>
         </Col>      
        <Row className="justify-content-md-center mx-0"></Row>
          <Col xs="12" lg="12" className="contentpage">
          <h3>PRIVACY</h3>
           <div className="content_par" dangerouslySetInnerHTML={{__html: decodedString}} />
          {/*<p className="content_par">{decodedString}</p>*/}
          </Col>
          
          <Col xs="8" lg="8" md="7" sm="7"  className="boxwrap termsandpri"><NavLink to="/login"><Button variant="grdantpink">BACK</Button></NavLink></Col>
         
        </Row>
       </Container>
      </section>
      </>
    )
}
export default Privacy;