import React, { useEffect ,useState} from 'react';
import { Row, Col, Container, Image} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Gamepick.css';
import Topbar from "../Topbar";
import soundFile from '../tick.wav';
const Gamepick = () => {


  const [audio] = useState(new Audio(soundFile)); 
  const playAudio = () => {
    if(sessionStorage.getItem('tickData') != 'unmute'){
      audio.play(); 
    }    
  };

useEffect(() => {  
  document.title = `Gamepick | PVRPOSE`;
  document.getElementById('bodywrap').className='tutralpg';
  window.scrollTo({top: 0,left: 0,behavior: 'smooth',}); 
},[])  

    return (
      <>

      <section className="gen-wrap">
      <Topbar/>
       <Container>
        <Row className="h-100-center-nev p-20">
         <Col md={12} lg={12} xl={12} xxl={12} className="text-center">
          <Row className="justify-content-md-center mx-0">
            <Col xs="12" lg="8" md="8" className="mb-4 game-top-txt">              
              <p className="">Question Types</p>
              <h3>Connect <span>Deeper</span> with:</h3>
            </Col>
            <Col xs="12" lg="8" md="8" className="gamelink">
              {/* <NavLink to="/addplayer"><Button className="mb-4" variant="grdantpink">FRIENDS</Button></NavLink>
              <NavLink to="/addplayer"><Button className="mb-4" variant="grdantpink">DATE</Button></NavLink>
              <NavLink to="/addplayer"><Button className="mb-4" variant="grdantpink">STRANGER</Button></NavLink> */}

              <NavLink to="/addplayer?type=f" onClick={playAudio}><span>FRIENDS</span><Image src="assets/images/gmbtn.png" fluid /></NavLink>
              <NavLink to="/addplayer?type=d" onClick={playAudio}><span>DATE</span><Image src="assets/images/gmbtn.png" fluid /></NavLink>
              <NavLink to="/addplayer?type=s" onClick={playAudio}><span>STRANGER</span><Image src="assets/images/gmbtn.png" fluid /></NavLink>              
            </Col>
          </Row>
         </Col>
        </Row>
       </Container>
      </section>
      </>
    )
}
export default Gamepick;