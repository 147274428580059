import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Container  } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Typewriter from 'typewriter-effect';
import './Animation.css';

const Animation = () => {
const navigate = useNavigate();
const [displayText, setDisplayText] = useState('');

useEffect(() => {
  document.title = `Animation | PVRPOSE`;
  document.getElementById('bodywrap').className='animpg';  
  setDisplayText('The ultimate connection game in the world!');
  const timer = setTimeout(() => {
    navigate('/video');
  }, 3100);
  return () => clearTimeout(timer);
}, []);
 
    return (
      <>
      <section className="gen-wrap">
        <Container>
          <Row>
            <Col md={12} lg={12} xl={12} xxl={12} className="text-center typrtxt anim-wrap">
              <Typewriter options={{strings: [displayText], autoStart: true, loop: true, delay: 25,}}/>
            </Col>
          </Row>
        </Container>
      </section> 
      </>
    )
}

export default Animation;