import React, {useState, useEffect } from 'react';
import { Row, Col, Container, Image,} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink } from "react-router-dom";
import './Pose.css';
import Topbar from "../Topbar";
import axios from "axios";
import queryString from 'query-string';
import $ from 'jquery';
import soundFile from '../tick.wav';
const Pose = () => {
  const [audio] = useState(new Audio(soundFile)); 
  const playAudio = () => {
    if(sessionStorage.getItem('tickData') != 'unmute'){
      audio.play(); 
    }    
  };
useEffect(() => {  
   document.title = `Pose | PVRPOSE`;
   document.getElementById('bodywrap').className='posepg';
   window.scrollTo({top: 0,left: 0,behavior: 'smooth',}); 
},[])  

var arr=[];
const [post, setPost] = React.useState('');
const [questionx, setquestion] = React.useState('');

const [qidtop, setquestionidtop] = React.useState('');
const [qidbotom, setquestionidbottom] = React.useState('');

const [playerimg, setplayerimge] = React.useState('');

const userData = JSON.parse(sessionStorage.getItem("userData"));
const userid = userData?.userid;

var typeset='';
if(queryString.parse(window.location.search).type){
  typeset=queryString.parse(window.location.search).type;
}else{
  typeset='';
}

var suid='';
if(queryString.parse(window.location.search).uid){
  suid=queryString.parse(window.location.search).uid;
}else{
  suid='';
}

var plyerid='';
if(queryString.parse(window.location.search).plyerid){
  plyerid=queryString.parse(window.location.search).plyerid;
}else{
  plyerid='';
}
var quid='';
if(queryString.parse(window.location.search).qid){
  quid=queryString.parse(window.location.search).qid;
}else{
  quid='';
}

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  React.useEffect(() => {
     const params = {
            userid: userid,
            typeset:typeset,
            plyerid:plyerid,
            quid:quid
          };

    axios.get('https://pvrpose.app/pvr/apis/items/pose.php', { params }).then((response) => {
              setplayerimge(response.data.playerimg);          
              setPost(response.data.qutop);  
              setquestionidtop(response.data.qutopid);          
              setquestion(response.data.qubtm); 
              setquestionidbottom(response.data.qubtmid);    
              console.log(response.data);            
              
    });   
  }, []);

  var topurl='/top?id='+qidtop+'&type='+typeset+'&uid='+suid;
  var btmurl='/bottom?id='+qidbotom+'&type='+typeset+'&uid='+suid;
  
    return (
      <>
      <section className="gen-wrap">
      <Topbar/>
       <Container>
        <Row className="justify-content-center p-20 clasmtoppose">
         <Col xs={11} md={10} lg={10} xl={10} xxl={10} className="text-center pose-mainbox pose-mainbox_topx">
          <Row className="justify-content-md-center mx-0 cusradio">
          <Col xs="12" lg="12" className="set-top-img playerimg_q"><Image src={playerimg} fluid /></Col>
            <Col xs={12} md={12} className="top-box">
            <NavLink to={topurl}  onClick={playAudio}>
              <div className="main">
               <h3>TOP</h3>
               <p className="firstptag">{post}</p>
              </div>
            </NavLink>
            </Col>
            <Col xs={12} md={12} className="botm-box">
            <NavLink to={btmurl}  onClick={playAudio}>
            <div className="main">
             <h3>BOTTOM</h3>
             <p className="firstptag">{questionx}</p>
            </div>
            </NavLink>
            </Col>
          </Row>
         </Col>
        </Row>
       </Container>
      </section>

       {/*<section className="gen-wrap">
      <Topbar/>
       <Container>
        <Row className="justify-content-center p-20 pt-80 topposediv">
         <Col xs={11} md={10} lg={10} xl={10} xxl={10} className="text-center pose-mainbox pos_posediv">
          <Row className="justify-content-md-center mx-0 cusradio inn_posediv">
          <Col xs="12" lg="12" className="set-top-img playerimg_q"><Image src={playerimg} fluid /></Col>
            <Col xs={12} md={12} className="top-box topposediv_box">
            <NavLink to={topurl}  onClick={playAudio}>
              <div className="main">
               <h3>TOP</h3>
               <p className="firstptag">{post}</p>
              </div>
            </NavLink>
            </Col>
            <Col xs={12} md={12} className="botm-box btmposediv_box">
            <NavLink to={btmurl}  onClick={playAudio}>
            <div className="main">
             <h3>BOTTOM</h3>
             <p className="firstptag">{questionx}</p>
            </div>
            </NavLink>
            </Col>
          </Row>
         </Col>
        </Row>
       </Container>
      </section>*/}
      </>
    )
}
export default Pose;