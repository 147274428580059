import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Image, Button, Form} from 'react-bootstrap';
import { NavLink, useSearchParams } from "react-router-dom";
import './Login.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import Alert from "@mui/material/Alert";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { useJwt } from "react-jwt";
import $ from 'jquery';

const Login = () => {
  const [searchParams, setSearchParams] = useSearchParams();
const isVerified = searchParams.get("isVerified");
useEffect(() => {  
   document.title = `Login | PVRPOSE`;
   document.getElementById('bodywrap').className='tutralpg';
   window.scrollTo({top: 0,left: 0,behavior: 'smooth',}); 
},[])  

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState("");


  const [showjwr, setjwwt] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
      axios.post("https://pvrpose.app/pvr/apis/items/login.php", {
        email: email,
        password: password,
        type: 'notgmail'
      })
      .then((response) => {
        console.log(response);
        if (response.data.status === "success") {
          sessionStorage.setItem("loggedIn", true);
          sessionStorage.setItem("userData",JSON.stringify(response.data.data)
          );

          window.location.href = "/profile";
        } else {
          setError(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleLoginSuccess = (response) => {  
    setShowPassword(response.credential); 
   // logingmail();
  };

  const handleLoginFailure = (error) => {
    console.error('Login failed:', error);
  //setError(error);
  
  if(error.error==='popup_closed_by_user')
  {
    //window.location.href = '/login';
  }
  };

  //async function logingmail(){
    const userObject = useJwt(showPassword);
    // console.log('xxxx:', userObject.decodedToken);
    if(userObject.decodedToken){
      console.log('email:', userObject.decodedToken.email);
      console.log('name:', userObject.decodedToken.name);
      console.log('picture:', userObject.decodedToken.picture);

      axios.post("https://pvrpose.app/pvr/apis/items/login.php", {
        email: userObject.decodedToken.email,
        password: '',
        name: userObject.decodedToken.name,
        picture: userObject.decodedToken.picture,
        type: 'gmail',
      })
      .then((response) => {
        console.log(response);
        if (response.data.status === "success") {
          sessionStorage.setItem("loggedIn", true);
          sessionStorage.setItem("userData",JSON.stringify(response.data.data)
          );

          window.location.href = "/profile";
        } else {
          setError(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }
    
  //}

$(document).on('click','.clickbuttongmail',function(){

  $('.gmailloginbtn iframe').trigger('click');


  return false;
});

    return (
      <>
      <section className="gen-wrap">
       <Container>
        <Row className="p-20 h-100-center-normal">
         <Col md={12} lg={12} xl={12} xxl={12} className="text-start mt-3">
          <a href="/"><Image src="assets/images/svg/logo.svg" fluid /></a>
         </Col>
        <Row className="justify-content-md-center mx-0">
          <Col xs="9" lg="8" md="8" className="detail-login">
          <p className="txt-head">LOGIN</p>
          {error && (
            <Alert style={{ marginBottom: "20px" }} severity="error">
              {error}
            </Alert>
          )}
          {(isVerified) && (
            <Alert style={{ marginBottom: "20px" }} severity="success">
              Your account has been verified!
            </Alert>
          )}
          
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control type="email" onChange={handleEmailChange} value={email} id="email" placeholder="Enter Email" />
              
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control type="password" value={password} onChange={handlePasswordChange} id="password" placeholder="Enter password" />              
            </Form.Group>
            <Button className="mb-3 text-start px-md-4 px-3" variant="grdantpink" type="submit">Join</Button>

              

           {/* <NavLink to="#">
            <Button className="mb-3 text-start px-md-4 px-3 clickbuttongmail" variant="grdantpink"><i class="goglicon">
              <img src="assets/images/google.png" className="img-fluid " alt="Google_Icon"/></i> Sign in with Google</Button>
            </NavLink>*/}
            
                <GoogleOAuthProvider clientId="632302129512-47tag0r7kuk01tca1soe3pdvigc1dk2h.apps.googleusercontent.com">
                <GoogleLogin
                      onSuccess={handleLoginSuccess}
                      shape="rectangular"
                      theme="filled_blue"
                      size="large"                      
                      onError={() => {
                        console.log('Login Failed');
                      }}
                    
                    />
              </GoogleOAuthProvider>
            <div className="dwn-txt-lgn">
              <NavLink to="/register"><p>Create an account</p></NavLink>            
            </div>
            <div className="dwn-txt-lgn">
              <NavLink to="/forgot"><p className="fp">Forgot the password?</p></NavLink>            
            </div>
 
          </Form>
          </Col>
          <Col xs="12" lg="6" className="align-self-center">
          <Row className="justify-content-md-center trm-prvcy-txt">
           <Col xs="6" lg="6" className="text-center"><NavLink to="/terms"><p>Terms</p></NavLink></Col>
           <Col xs="6" lg="6" className="text-center"><NavLink to="/privacy"><p>Privacy</p></NavLink></Col>
          </Row>
          </Col>
        </Row>
        </Row>
       </Container>
      </section>
      </>
    )
}
export default Login;