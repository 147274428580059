import React, { useEffect,useState } from 'react';
import { Row, Col, Containerm,Button,Container} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Questionone.css';
import Topbar from "../Topbar";
import axios from "axios";
import queryString from 'query-string';
import soundFile from '../tick.wav';
const Questionone = () => {
const [audio] = useState(new Audio(soundFile)); 
  const playAudio = () => {
    if(sessionStorage.getItem('tickData') != 'unmute'){
      audio.play(); 
    }    
  };
useEffect(() => {  
   document.title = `Question | PVRPOSE`;
   document.getElementById('bodywrap').className='posepg qustnpg';
   window.scrollTo({top: 0,left: 0,behavior: 'smooth',}); 
},[]) 

var id='';
if(queryString.parse(window.location.search).id){
  id=queryString.parse(window.location.search).id;
}else{
  id='';
} 

var typeset='';
if(queryString.parse(window.location.search).type){
  typeset=queryString.parse(window.location.search).type;
}else{
  typeset='';
}

var suid='';
if(queryString.parse(window.location.search).uid){
  suid=queryString.parse(window.location.search).uid;
}else{
  suid='';
}

const [post, setPost] = React.useState(null);
const [question, setquestion] = React.useState(null);

const userData = JSON.parse(sessionStorage.getItem("userData"));
const userid = userData?.userid;

 React.useEffect(() => {
  const params = {
    id: id
  };
    axios.get('https://pvrpose.app/pvr/apis/items/top.php', { params }).then((response) => {
      setPost(response.data);
      setquestion(response.data.question);           
    });
  }, []);

var url="/player?type="+typeset+"&uid="+suid;
    return (
      <>
      <section className="gen-wrap topcont_sec">
      <Topbar/>
       <Container>
        <Row className="justify-content-center p-20 pt-80 quebot topcont_main">
         <Col xs={11} md={10} lg={10} xl={10} xxl={10} className="text-center pose-mainbox">
          <Row className="justify-content-md-center mx-0 topcont_main_row">
          <Col xs="12" lg="12" className="set-mian-cont topcont_main_box"> 
            <h3>BOTTOM</h3>
            <p>{question}</p>
             <div className="text-center pose-mainbox_bottomnext_nxt col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-11">            
            <NavLink to={url} onClick={playAudio} className="nextbottom"><Button className="startgame mb-4 strbtn" variant="grdantpink"><span>NEXT</span></Button></NavLink>
         </div>
          </Col>

          </Row>

         </Col>
         
        </Row>
       </Container>
      </section>
      </>
    )
}
export default Questionone;
