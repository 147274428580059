import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Image,} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink } from "react-router-dom";
import './Player.css';
import Topbar from "../Topbar";
import axios from "axios";
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import soundFile from '../tick.wav';

const Player = () => {
const [audio] = useState(new Audio(soundFile)); 
  const playAudio = () => {
    if(sessionStorage.getItem('tickData') != 'unmute'){
      audio.play(); 
    }    
  };

const navigate = useNavigate();

const userData = JSON.parse(sessionStorage.getItem("userData"));
  const userid = userData?.userid;
  
useEffect(() => {  
   document.title = `Player | PVRPOSE`;
   document.getElementById('bodywrap').className='pictrpg';
   window.scrollTo({top: 0,left: 0,behavior: 'smooth',}); 
},[])  


var typeset='';
if(queryString.parse(window.location.search).type){
  typeset=queryString.parse(window.location.search).type;
}else{
  typeset='';
}

var suid='';
if(queryString.parse(window.location.search).uid){
  suid=queryString.parse(window.location.search).uid;
}else{
  suid='';
}


var arr=[];
const [post, setPost] = React.useState(arr);


 React.useEffect(() => {
     const params = {
       userid: userid,
       uniqid:suid,
       typeset:typeset
     };

    axios.get('https://pvrpose.app/pvr/apis/items/listplayer.php', { params }).then((response) => {
      setPost(response.data.dataretn);     
      console.log(response.data);
    });   
  }, []);

 // console.log(post);

    return (
      <>
      <section className="gen-wrap">
      <Topbar/>
       <Container>
        <Row className="justify-content-center xh-100-center-nev p-20">
         <Col xs={12} sm={10} md={8} lg={8} xl={8} xxl={8} className="text-center">
            <Col xs="12" lg="12" className="mb-4 playrmain-top-txt"><h3>Pick who asks next!</h3></Col>
          <Row className="justify-content-md-center mx-0 cusradio playradio">
            {post?.length > 0 && (
              post?.map((item) => {
                var url="/pose?type="+typeset+"&uid="+suid+"&plyerid="+item.playeid+"&qid="+item.questionss;
                return (

                   // <Col xs="6" md={6} className="mb-3 mp-0">
                   //   <NavLink to={url}>
                   //   <input class="radio-label" type="radio" name="slctbox" id="one" checked="checked"/>
                   //   <label class="plyr-box plyr-boxcs" for="one">
                   //       <Image src={item.playeimg} fluid />                         
                   //       <h3>{item.playername}</h3>
                   //   </label>
                   //   </NavLink>
                   //   </Col>

                     <Col xs={6} md={6} className="mb-3 mp-0">
                      <input className="radio-label" type="radio" name="slctbox" id={`three_${item.playeid}`} onClick={() => { setTimeout(()=> { navigate(url); }, 500); }}/>
                     <label className="plyr-box plyr-boxcs" for={`three_${item.playeid}`}  onClick={playAudio}>
                        <Image src={item.playeimg} fluid />
                        <h3>{item.playername}</h3>
                    </label>
                    </Col>
                  );
              })
            )}
          </Row>
         </Col>
        </Row>
       </Container>
      </section>

       {/*<section className="gen-wrap">
      <Topbar/>
       <Container>
        <Row className="justify-content-center h-100-center-nev p-20">
         <Col xs={12} sm={10} md={8} lg={8} xl={8} xxl={8} className="text-center">
            <Col xs="12" lg="12" className="mb-4 playrmain-top-txt"><h3>Pick who asks next!</h3></Col>
          <Row className="justify-content-md-center mx-0 cusradio playradio">
            <Col xs={6} md={6} className="mb-3 mp-0">

            <input class="radio-label" type="radio" name="slctbox" id="one" defaultChecked={true}/>
            <label class="plyr-box" for="one">
                <Image src="assets/images/plyr-imgone.png" fluid />
                <h3>Farah</h3>
            </label>

            </Col>
            <Col xs={6} md={6} className="mb-3 mp-0">
            <input class="radio-label" type="radio" name="slctbox" id="two" onClick={() => { setTimeout(()=> { navigate('/pose'); }, 1000); }}/>

             <label class="plyr-box" for="two">
                <Image src="assets/images/plyr-imgone.png" fluid />
                <h3>Juan</h3>
            </label>

            </Col>
            <Col xs={6} md={6} className="mb-3 mp-0">
            <input class="radio-label" type="radio" name="slctbox" id="three" onClick={() => { setTimeout(()=> { navigate('/pose'); }, 1000); }}/>
             <label class="plyr-box" for="three">
                <Image src="assets/images/plyr-imgtwo.png" fluid />
                <h3>Tom</h3>
            </label>
            </Col>
            <Col xs={6} md={6} className="mb-3 mp-0">
            <input class="radio-label" type="radio" name="slctbox" id="four" onClick={() => { setTimeout(()=> { navigate('/pose'); }, 1000); }}/>
             <label class="plyr-box" for="four">
                <div className="border-img"><Image src="assets/images/plyr-imgtwo.png" fluid /></div>
                <h3>Ava</h3>
            </label>
            </Col>
            <Col xs={6} md={6} className="mb-3 mp-0">
            <input class="radio-label" type="radio" name="slctbox" id="five" onClick={() => { setTimeout(()=> { navigate('/pose'); }, 1000); }}/>
             <label class="plyr-box" for="five">
                <div className="border-img"><Image src="assets/images/usericon.png" fluid /></div>
                <h3>Tom</h3>
            </label>
            </Col>
            <Col xs={6} md={6} className="mb-3 mp-0">
            <input class="radio-label" type="radio" name="slctbox" id="sixx" onClick={() => { setTimeout(()=> { navigate('/pose'); }, 1000); }}/>
             <label class="plyr-box" for="sixx">
                <div className="border-img"><Image src="assets/images/usericon.png" fluid /></div>
                <h3>Ava</h3>
            </label>
            </Col>
            <Col xs={6} md={6} className="mb-3 mp-0">
            <input class="radio-label" type="radio" name="slctbox" id="seven" onClick={() => { setTimeout(()=> { navigate('/pose'); }, 1000); }}/>
             <label class="plyr-box" for="seven">
                <div className="border-img"><Image src="assets/images/usericon.png" fluid /></div>
                <h3>Juan</h3>
            </label>
            </Col>
            <Col xs={6} md={6} className="mb-3 mp-0">
            <input class="radio-label" type="radio" name="slctbox" id="eight" onClick={() => { setTimeout(()=> { navigate('/pose'); }, 1000); }}/>
             <label class="plyr-box" for="eight">
                <div className="border-img"><Image src="assets/images/usericon.png" fluid /></div>
                <h3>Juan</h3>
            </label>
            </Col>
          </Row>
         </Col>
        </Row>
       </Container>
      </section>*/}
      </>
    )
}
export default Player;