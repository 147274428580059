import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Image} from 'react-bootstrap';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { googleLogout } from '@react-oauth/google';
function Topbar() {

    const navigate = useNavigate();
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const userid = userData?.userid;

    useEffect(() => {
    const loggedIn = sessionStorage.getItem("loggedIn");
    if (!loggedIn) {
      navigate("/login");
    }
    }, [navigate]);


  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive); 
   };

  return (
    <Navbar expand="lg" className="top-bar-cus">
        <Navbar.Brand href="/home"><Image src="assets/images/svg/logo.svg" fluid /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={ToggleClass}/>
        <Navbar.Collapse id="basic-navbar-nav" className={isActive ? null : "active"}>
          <Nav className="me-auto">
            {/* <Nav.Link href="/tutorial">Tutorial</Nav.Link> */}
            {/*<Nav.Link href="/login">login</Nav.Link>*/}

            <Nav.Link href="/home">Home</Nav.Link>
            <Nav.Link href="/profile">Profile</Nav.Link>
            <Nav.Link href="/tutorial">Tutorial</Nav.Link>
            {/*<Nav.Link href="/changepsw">Change Password</Nav.Link>*/}
            {/*<Nav.Link href="/gamepick">Gamepick</Nav.Link>
            <Nav.Link href="/addplayer">Addplayer</Nav.Link>
            <Nav.Link href="/picture">Picture</Nav.Link>
            <Nav.Link href="/player">Player</Nav.Link>
            <Nav.Link href="/pose">Pose</Nav.Link>
            <Nav.Link href="/top">Question</Nav.Link>*/}
            <Nav.Link href="/logout">Logout</Nav.Link>
          </Nav>
        </Navbar.Collapse>
    </Navbar>
  );
}
export default Topbar;