import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Image, Button, Form} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './Resetpsw.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import Alert from "@mui/material/Alert";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { useJwt } from "react-jwt";
import $ from 'jquery';
import queryString from 'query-string';

const Resetpsw = () => {
useEffect(() => {  
   document.title = `Resetpsw | PVRPOSE`;
   document.getElementById('bodywrap').className='tutralpg';
   window.scrollTo({top: 0,left: 0,behavior: 'smooth',}); 


},[])  
   
  const [psw, setpsw] = useState("");  
  const [cpsw, setcpsw] = useState("");  
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState("");


  const handlepswChange = (event) => {
    setpsw(event.target.value);
  }; 
  const handlecpswChange = (event) => {
    setcpsw(event.target.value);
  }; 

var recetlink='';
  if(queryString.parse(window.location.search).recetlink){
    recetlink=queryString.parse(window.location.search).recetlink;      
  }



  const handleSubmit_psw = (event) => {
    event.preventDefault();
      axios.post("https://pvrpose.app/pvr/apis/items/forgotepsw.php", {
        recetlink: recetlink,
        psw:psw                
      }).then((response) => {
       
        if (response.data.status === "success") {          
          alert('Password has been reset successfully');
          window.location.href = "/login";
          
        } else {
          setError(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleLoginFailure = (error) => {
    console.error('Login failed:', error);  
  if(error.error==='popup_closed_by_user')
  {
    //window.location.href = '/login';
  }
  };

    return (
      <>
      <section className="gen-wrap">
       <Container>
        <Row className="p-20 h-100-center-normal">
         <Col md={12} lg={12} xl={12} xxl={12} className="text-start mt-3">
          <Image src="assets/images/svg/logo.svg" fluid />
         </Col>
        <Row className="justify-content-md-center mx-0">
          <Col xs="9" lg="8" md="8" className="detail-login">
          <p className="txt-head">Enter New Password</p>
          {error && (
            <Alert style={{ marginBottom: "20px" }} severity="error">
              {error}
            </Alert>
          )}
          <Form onSubmit={handleSubmit_psw} className="resetpasw">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control type="password" onChange={handlepswChange} value={psw} id="password" placeholder="New password" />              
            </Form.Group>   
             <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control type="password" onChange={handlecpswChange} value={cpsw} id="cpassword" placeholder="Confirm password" />              
            </Form.Group>                  
            <Button className="mb-3 text-center px-md-4 px-3" variant="grdantpink" type="submit">Submit</Button>                     
          </Form>         
          </Col>
          <Col xs="12" lg="6" className="align-self-center">
          <Row className="justify-content-md-center trm-prvcy-txt">
           <Col xs="6" lg="6" className="text-center"><NavLink to="#"><p>Terms</p></NavLink></Col>
           <Col xs="6" lg="6" className="text-center"><NavLink to="#"><p>Privacy</p></NavLink></Col>
          </Row>
          </Col>
        </Row>
        </Row>
       </Container>
      </section>
      </>
    )
}
export default Resetpsw;