import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Image, Button} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css';
import Topbar from "../Topbar";
import Typewriter from 'typewriter-effect';
import soundFile from '../tick.wav';


const Home = () => {

  const [audio] = useState(new Audio(soundFile)); 
  const playAudio = () => {
    if(sessionStorage.getItem('tickData') != 'unmute'){
      audio.play(); 
    }    
  };



const [displayText, setDisplayText] = useState('');  
useEffect(() => {  
   document.title = `Home | PVRPOSE`;
   document.getElementById('bodywrap').className='tutralpg';
   window.scrollTo({top: 0,left: 0,behavior: 'smooth',}); 
  setDisplayText('Supercharge Conversations. Deepen Connections. Change Your Life.');
},[])  

    return (
      <>
      <section className="gen-wrap">
      <Topbar/>
       <Container>
        <Row className="p-20 home-set-center textanimation_top">
        {/*justify-content-md-center */}
          <Col xs="12" lg="12" className="text-center detail-home">   
          {/*   align-self-center       */}
          <div className="typrtxt anim-wrap text_classani">
              {/*<Typewriter options={{strings: [displayText], autoStart: true, loop: true, delay: 105,}}/>*/}
              <Typewriter options={{ autoStart: false, loop: false, delay: 50,}} onInit={(typewrier) => {
                typewrier.typeString('Supercharge Conversations.<br> Deepen Connections.<br> Change Your Life.').start();
              }}
              />
          </div>   
          <h3 className="d-none">The ultimate</h3>
          <h3 className="d-none">connection game</h3>
          <h3 className="d-none">in the world!</h3>
          {/* <NavLink to="/gamepick"><Button className="mb-3 p-3 px-md-4 px-3" variant="grdantpink">Let’s Play</Button></NavLink> */}
          {/*<NavLink to="/gamepick"><span className="lp">Let’s Play</span><Image src="assets/images/lp.png" fluid /></NavLink>*/}
          <NavLink to="/gamepick" onClick={playAudio}><Button className="mb-3 p-3 px-md-4 px-3 lpbutton" >Let’s Play</Button></NavLink>
          </Col>
        </Row>
        <Row>
          <Col xs="12" lg="12" className="text-center px-0 lst-full-img"><Image src="assets/images/Page-5-Couple.jpg" fluid /></Col>
        </Row>
       </Container>
      </section>
      </>
    )
}
export default Home;