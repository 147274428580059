import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Image, Button, Form} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './Changepsw.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Topbar from "../Topbar";
import axios from "axios";
import Alert from "@mui/material/Alert";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { useJwt } from "react-jwt";
import $ from 'jquery';
import queryString from 'query-string';
import soundFile from '../tick.wav';

const Changepsw = () => {
   const [audio] = useState(new Audio(soundFile)); 
  const playAudio = () => {
    if(sessionStorage.getItem('tickData') != 'unmute'){
      audio.play(); 
    }    
  };
useEffect(() => {  
   document.title = `Changepsw | PVRPOSE`;
   document.getElementById('bodywrap').className='tutralpg';
   window.scrollTo({top: 0,left: 0,behavior: 'smooth',}); 


},[])  

const userData = JSON.parse(sessionStorage.getItem("userData"));
const userid = userData?.userid;
   
  const [psw, setpsw] = useState("");  
  const [cpsw, setcpsw] = useState("");  
  const [opsw, setopsw] = useState("");  
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState("");


  const handlepswChange = (event) => {
    setpsw(event.target.value);
  }; 
  const handlecpswChange = (event) => {
    setcpsw(event.target.value);
  }; 
  const handlopswChange = (event) => {
    setopsw(event.target.value);
  };


  const handleSubmit_psw = (event) => {
    event.preventDefault();
      axios.post("https://pvrpose.app/pvr/apis/items/changepsw.php", {
        userid: userid,
        oldpsw:opsw,                
        psw:psw,                
        cpsw:cpsw,                
      }).then((response) => {
       
        if (response.data.status === "success") {          
          alert('Your password has been changed');
          window.location.href = "/logout";
          
        } else {
          setError(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleLoginFailure = (error) => {
    console.error('Login failed:', error);  
  if(error.error==='popup_closed_by_user')
  {
    //window.location.href = '/login';
  }
  };

    return (
      <>
      <section className="gen-wrap">
        <Topbar/>
       <Container>
        <Row className="p-20 h-100-center-normal">
        
        <Row className="justify-content-md-center mx-0">
          <Col xs="9" lg="8" md="8" className="detail-login">
          <p className="txt-head">Change Password</p>
          {error && (
            <Alert style={{ marginBottom: "20px" }} severity="error">
              {error}
            </Alert>
          )}
          <Form onSubmit={handleSubmit_psw} className="resetpasw">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control type="password" onChange={handlopswChange} value={opsw} id="oldpassword" placeholder="Enter old password" />              
            </Form.Group>  
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control type="password" onChange={handlepswChange} value={psw} id="password" placeholder="New password" />              
            </Form.Group>   
             <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control type="password" onChange={handlecpswChange} value={cpsw} id="cpassword" placeholder="Confirm password" />              
            </Form.Group>                  
            <Button  onClick={playAudio} className="mb-3 text-center px-md-4 px-3" id="cp-btn"  type="submit">Submit</Button>                     
          </Form>         
          </Col>
         
        </Row>
        </Row>
       </Container>
      </section>
      </>
    )
}
export default Changepsw;