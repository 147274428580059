import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Image, Button, Form} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './Forgot.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import Alert from "@mui/material/Alert";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { useJwt } from "react-jwt";
import $ from 'jquery';
import queryString from 'query-string';

const Forgot = () => {
useEffect(() => {  
   document.title = `Forgot | PVRPOSE`;
   document.getElementById('bodywrap').className='tutralpg';
   window.scrollTo({top: 0,left: 0,behavior: 'smooth',}); 
},[])  

  const [email, setEmail] = useState("");    
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }; 

  const handleSubmit = (event) => {
    event.preventDefault();
      axios.post("https://pvrpose.app/pvr/apis/items/forgotepsw.php", {
        email: email                
      }).then((response) => {
        console.log(response);
        if (response.data.status === "success") {          
          //window.location.href = "/profile";
          $('.emailcontent').show();
        } else {
          setError(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleLoginFailure = (error) => {
    console.error('Login failed:', error);  
  if(error.error==='popup_closed_by_user')
  {
    //window.location.href = '/login';
  }
  };

    return (
      <>
      <section className="gen-wrap">
       <Container>
        <Row className="p-20 h-100-center-normal">
         <Col md={12} lg={12} xl={12} xxl={12} className="text-start mt-3">
          <a href="/"><Image src="assets/images/svg/logo.svg" fluid /></a>
         </Col>
        <Row className="justify-content-md-center mx-0">
          <Col xs="9" lg="8" md="8" className="detail-login">
          <p className="txt-head">Forgot Password</p>
          {error && (
            <Alert style={{ marginBottom: "20px" }} severity="error">
              {error}
            </Alert>
          )}
          <Form onSubmit={handleSubmit} className="forgotpasw">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control type="email" onChange={handleEmailChange} value={email} id="email" placeholder="Enter Email" />              
            </Form.Group>         
            <Button className="mb-3 text-center px-md-4 px-3 sendbutton" variant="grdantpink" type="submit">SEND</Button>            
            {/*<div className="dwn-txt-lgn">
              <NavLink to="/register"><p>create an account</p></NavLink>            
            </div>  */} 
          </Form>
          <div className="emailcontent">
            <span>We are writing to inform you that your password change request has been successfully processed. To ensure the security of your account, please check your email inbox or spam folder for further instructions.</span>           
            <span>Thank you for your cooperation in maintaining the security of your account.</span>
          </div>
          </Col>
          <Col xs="12" lg="6" className="align-self-center">
          <Row className="justify-content-md-center trm-prvcy-txt">
           <Col xs="6" lg="6" className="text-center"><NavLink to="/terms"><p>Terms</p></NavLink></Col>
           <Col xs="6" lg="6" className="text-center"><NavLink to="/privacy"><p>Privacy</p></NavLink></Col>
          </Row>
          </Col>
        </Row>
        </Row>
       </Container>
      </section>
      </>
    )
}
export default Forgot;
